import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const YouTubeSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className, ...props }) => (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 32.4231 23.776"
      className={className}
      {...props}
    >
      <path d="M24.445 0H7.977A7.978 7.978 0 000 7.977v7.822a7.977 7.977 0 007.977 7.977h16.468a7.977 7.977 0 007.978-7.976V7.977A7.977 7.977 0 0024.446 0zM22.8 11.96l-10.827 5.849a.543.543 0 01-.8-.477V5.587a.543.543 0 01.8-.477l10.832 5.9a.543.543 0 01-.005.95z" />
    </svg>
  )
);
