import { ChangeEvent } from 'react';

import { AnyFunction, Dict } from './types';

// Number assertions
export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isNotNumber(value: unknown): boolean {
  return (
    typeof value !== 'number' || Number.isNaN(value) || !Number.isFinite(value)
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNumeric(value: any): value is number | string {
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat
  return value != null && value - parseFloat(value) + 1 >= 0;
}

// Array assertions
export function isArray(value: unknown): boolean {
  return Array.isArray(value);
}

export function isEmptyArray<T>(value: T[]): boolean {
  return isArray(value) && value.length === 0;
}

// Function assertions
export function isFunction(value: unknown): value is AnyFunction {
  return typeof value === 'function';
}

// Generic assertions
export function isDefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined' && value !== undefined;
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return typeof value === 'undefined' || value === undefined;
}

// Object assertions
export function isObject(value: unknown): value is Dict {
  const type = typeof value;
  return (
    value != null &&
    (type === 'object' || type === 'function') &&
    !isArray(value)
  );
}

export function isEmptyObject(value: unknown): boolean {
  return isObject(value) && Object.keys(value).length === 0;
}

export function isNotEmptyObject(value: unknown): value is Dict {
  return !!value && !isEmptyObject(value);
}

export function isNull(value: unknown): value is null {
  return value == null;
}

// String assertions
export function isString(value: unknown): value is string {
  return Object.prototype.toString.call(value) === '[object String]';
}

// Event assertions
export function isInputEvent(value: unknown): value is ChangeEvent {
  return !!value && isObject(value) && isObject(value.target);
}

// Empty assertions
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmpty(value: any): boolean {
  if (isArray(value)) return isEmptyArray(value);
  if (isObject(value)) return isEmptyObject(value);
  if (value == null || value === '') return true;
  return false;
}

export const __DEV__ = process.env.NODE_ENV !== 'production';
