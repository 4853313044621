import React, { FC } from 'react';

import { LocaleProvider } from '~src/context/locale-context';
import { GlobalStyle } from '~src/styles';
import { PageContext } from '~src/utils';

import { Header } from './components/header';

type Props = {
  pageContext: PageContext;
  children: JSX.Element;
};

const AppLayout: FC<Props> = ({
  children,
  pageContext: { pageID, locale, uid },
}) => {
  return (
    <>
      <GlobalStyle />
      <LocaleProvider value={{ pageID, locale, uid }}>
        <Header />
        <main>{children}</main>
      </LocaleProvider>
    </>
  );
};

export default AppLayout;
