export default [
  {
    id: 1,
    default: true,
    languageName: `Srpski`,
    locale: `sr`,
    htmlLang: `sr`,
    ogLang: `sr_Latn`,
    dateFormat: `DD.MM.YYYY`,
    siteTitle: `Hotel Maestral Čanj`, // Manifest 'name'
    siteTitleAlt: `Maestral Čanj`, // Title for JSONLD.
    siteShortName: `Maestral`, // Manifest 'short_name'
    siteTitleTemplate: `%s | Maestral Čanj`, // Titles to inherit from a template
    siteDescription: `Doživite letenje u tandemu paraglajderom na Zlatiboru sa Petrom Loncarom, jednim od najiskusnijih pilota u Srbiji.`, // Manifest 'description'
    siteFeaturedImageAlt: `Doživite letenje u tandemu paraglajderom na Zlatiboru sa Petrom Loncarom, jednim od najiskusnijih pilota u Srbiji.`,
    startUrl: `/`, // Manifest 'start_url'
    slugs: [
      {
        id: 1,
        pageID: `about`,
        localSlug: `o-nama`,
      },
      {
        id: 2,
        pageID: `gallery`,
        localSlug: `galerija`,
      },
      {
        id: 3,
        pageID: `rooms`,
        localSlug: `sobe`,
      },
      {
        id: 4,
        pageID: `attractions`,
        localSlug: `atrakcije`,
      },
      {
        id: 5,
        pageID: `contact`,
        localSlug: `kontakt`,
      },
      {
        id: 6,
        pageID: `privacy`,
        localSlug: `politika-privatnosti`,
      },
      {
        id: 7,
        pageID: `terms`,
        localSlug: `uslovi-upotrebe`,
      },
    ],
    roomSlugs: [
      {
        id: 1,
        pageID: `triple-room`,
        localSlug: `trokrevetna-soba`,
      },
      {
        id: 2,
        pageID: `triple-room-queen`,
        localSlug: `trokrevetna-soba-francuski`,
      },
      {
        id: 3,
        pageID: `quadruple-room-queen`,
        localSlug: `cetvorokrevetna-soba-francuski`,
      },
    ],
  },
  {
    id: 2,
    default: false,
    languageName: `English`,
    locale: `en`,
    htmlLang: `en`,
    ogLang: `en_US`,
    dateFormat: `DD/MM/YYYY`,
    siteTitle: `Hotel Maestral Čanj`, // Manifest 'name'
    siteTitleAlt: `Maestral Čanj`, // Title for JSONLD.
    siteShortName: `Maestral`, // Manifest 'short_name'. shorter than 12 char
    siteTitleTemplate: `%s | Maestral Čanj`, // Titles to inherit from a template
    siteDescription: `Marketing sajt za hotel.`, // Manifest 'description'
    siteFeaturedImageAlt: `Marketing sajt za hotel.`, // Manifest 'description'
    startUrl: `/en/`, // Manifest 'start_url'
    slugs: [
      {
        id: 1,
        pageID: `about`,
        localSlug: `about`,
      },
      {
        id: 2,
        pageID: `gallery`,
        localSlug: `gallery`,
      },
      {
        id: 3,
        pageID: `rooms`,
        localSlug: `rooms`,
      },
      {
        id: 4,
        pageID: `attractions`,
        localSlug: `attractions`,
      },
      {
        id: 5,
        pageID: `contact`,
        localSlug: `contact`,
      },
      {
        id: 6,
        pageID: `privacy`,
        localSlug: `privacy-policy`,
      },
      {
        id: 7,
        pageID: `terms`,
        localSlug: `terms-of-use`,
      },
    ],
    roomSlugs: [
      {
        id: 1,
        pageID: `triple-room`,
        localSlug: `triple-room`,
      },
      {
        id: 2,
        pageID: `triple-room-queen`,
        localSlug: `triple-room-queen`,
      },
      {
        id: 3,
        pageID: `quadruple-room-queen`,
        localSlug: `quadruple-room-queen`,
      },
    ],
  },
];
