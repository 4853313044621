import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const LocationsSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => (
    <svg
      id="locations"
      aria-hidden="true"
      width="1em"
      height="1em"
      viewBox="0 0 70 72"
      fill="currentColor"
      className={className}
    >
      <path d="M23.9600799,33.6487434 C21.307582,30.6504968 17.7709182,29 13.999489,29 C10.2280598,29 6.691396,30.6504968 4.04006611,33.6487434 C-1.3466887,39.7399182 -1.3466887,49.6487434 4.04006611,55.7399182 L13.999489,67 L23.9589119,55.7399182 C29.3468347,49.6487434 29.3468347,39.7399182 23.9600799,33.6487434 Z M22.2104357,54.1899474 L14.000657,63.4722385 L5.78971024,54.1899474 C1.15747485,48.9544126 1.15747485,40.434249 5.78971024,35.1987142 C7.99136524,32.7089421 10.9078281,31.3378141 13.999489,31.3378141 C17.0911499,31.3378141 20.0087807,32.7089421 22.2092678,35.1987142 C26.8415031,40.434249 26.8415031,48.9532437 22.2104357,54.1899474 Z" />
      <path d="M55.5005786,29 L62.9812355,20.3782185 C67.0062548,15.7395438 67.0062548,8.19459746 62.9812355,3.5559228 C60.9907142,1.26261884 58.3335997,0 55.4994214,0 C52.6664003,0 50.0092858,1.26261884 48.0187645,3.5559228 C43.9937452,8.19459746 43.9937452,15.7395438 48.0187645,20.3782185 L55.5005786,29 Z M49.7500551,5.12739217 C51.2950237,3.34647244 53.3364653,2.36667075 55.4994214,2.36667075 C57.6635347,2.36667075 59.7049763,3.34647244 61.2499449,5.12739217 C64.5227323,8.89868201 64.5227323,15.0354593 61.2499449,18.8067491 L55.5005786,25.4346105 L49.7500551,18.8067491 C46.4772677,15.0354593 46.4772677,8.89868201 49.7500551,5.12739217 Z" />
      <path d="M60.1791176,64.025906 C59.6122662,63.89822 59.0496215,64.2548966 58.9223691,64.8236795 C58.8014268,65.3618601 58.5669038,65.8567751 58.2251103,66.2925958 C57.86649,66.7505769 57.9443138,67.4143329 58.4017913,67.7741752 C58.595299,67.9261321 58.8235119,68 59.0506731,68 C59.3630198,68 59.6711599,67.8617615 59.8783393,67.5968922 C60.4083822,66.9215284 60.7869843,66.1237549 60.974182,65.2879922 C61.1014343,64.7181539 60.7459691,64.153592 60.1791176,64.025906 Z" />
      <path d="M36.2906379,36.3858252 C35.7443989,36.3845181 35.2132982,36.2838724 34.7112125,36.0851951 C34.0653133,35.8303131 33.3323691,36.1662345 33.0825878,36.8406916 C32.8353295,37.5151487 33.1620637,38.2706452 33.8117475,38.5281414 C34.6014601,38.8392282 35.432803,38.9986929 36.2881148,39 L36.7384781,39 C37.4360997,39 38,38.4157319 38,37.6929126 C38,36.9700933 37.4360997,36.3858252 36.7384781,36.3858252 L36.2906379,36.3858252 L36.2906379,36.3858252 Z" />
      <path d="M33.2926088,29 C33.5261749,29 33.7610314,28.9444932 33.9739508,28.8289484 C34.545607,28.5162977 35.1779131,28.3248558 35.8502221,28.2591539 C36.5586629,28.1900536 37.0696694,27.6304542 36.9922442,27.0085513 C36.914819,26.3866484 36.2812225,25.9425938 35.56762,26.0060302 C34.5068944,26.1091143 33.5106899,26.410437 32.6073956,26.9054672 C32.0021884,27.2362425 31.8189487,27.9351753 32.1957514,28.4664549 C32.4409313,28.8108237 32.8616084,29 33.2926088,29 Z" />
      <path d="M20.75,68 L18.25,68 C17.55875,68 17,68.447 17,69 C17,69.553 17.55875,70 18.25,70 L20.75,70 C21.44125,70 22,69.553 22,69 C22,68.447 21.44,68 20.75,68 Z" />
      <path d="M41.5185859,47 C41.4110809,47 41.3048259,47.001995 41.1985709,47.005985 C40.5085383,47.0279302 39.9735131,47.4927681 40.0010144,48.0433915 C40.0272657,48.5800499 40.5822918,49 41.2485732,49 C41.266074,49 41.2835749,49 41.3010757,48.9990025 L43.7499411,48.9950125 C44.4412237,48.9950125 45,48.5491272 45,47.9975062 C45,47.4458853 44.4412237,47 43.7499411,47 L41.5185859,47 Z" />
      <path d="M40.25,28 L42.75,28 C43.44125,28 44,27.553 44,27 C44,26.447 43.44125,26 42.75,26 L40.25,26 C39.55875,26 39,26.447 39,27 C39,27.553 39.55875,28 40.25,28 Z" />
      <path d="M55.25,47 C54.55875,47 54,47.447 54,48 C54,48.553 54.55875,49 55.25,49 L57.75,49 C58.44125,49 59,48.553 59,48 C59,47.447 58.44125,47 57.75,47 L55.25,47 Z" />
      <path d="M59,37 C59,36.447 58.44125,36 57.75,36 L55.25,36 C54.55875,36 54,36.447 54,37 C54,37.553 54.55875,38 55.25,38 L57.75,38 C58.44,38 59,37.553 59,37 Z" />
      <path d="M48.25,49 L50.75,49 C51.44125,49 52,48.553 52,48 C52,47.447 51.44125,47 50.75,47 L48.25,47 C47.55875,47 47,47.447 47,48 C47,48.553 47.56,49 48.25,49 Z" />
      <path d="M49.75,28 C50.44125,28 51,27.553 51,27 C51,26.447 50.44125,26 49.75,26 L47.25,26 C46.55875,26 46,26.447 46,27 C46,27.553 46.55875,28 47.25,28 L49.75,28 Z" />
      <path d="M41.25,36 C40.55875,36 40,36.447 40,37 C40,37.553 40.55875,38 41.25,38 L43.75,38 C44.44125,38 45,37.553 45,37 C45,36.447 44.44125,36 43.75,36 L41.25,36 Z" />
      <path d="M52,37 C52,36.447 51.44125,36 50.75,36 L48.25,36 C47.55875,36 47,36.447 47,37 C47,37.553 47.55875,38 48.25,38 L50.75,38 C51.44,38 52,37.553 52,37 Z" />
      <path d="M68.9077388,42.0581853 C68.7867854,41.3723246 68.0789333,40.9037787 67.332596,41.0168327 C66.5821353,41.1261182 66.0722069,41.7717819 66.1931603,42.4563864 C66.2316455,42.679982 66.253637,42.9073461 66.250888,43.1497841 C66.250888,43.5718522 66.1849135,43.9888957 66.0557133,44.3908653 C65.8426704,45.0566275 66.2591349,45.7550498 66.9889785,45.9497538 C67.1181787,45.98367 67.2473789,46 67.3752046,46 C67.9703502,46 68.5187637,45.6457643 68.6946959,45.0968245 C68.896743,44.4649785 68.9998283,43.8105216 68.9998283,43.1585771 C69.0025772,42.7968045 68.9723389,42.4262387 68.9077388,42.0581853 Z" />
      <path d="M48.75,68 L46.25,68 C45.55875,68 45,68.447 45,69 C45,69.553 45.55875,70 46.25,70 L48.75,70 C49.44125,70 50,69.553 50,69 C50,68.447 49.44,68 48.75,68 Z" />
      <path d="M41.75,68 L39.25,68 C38.55875,68 38,68.447 38,69 C38,69.553 38.55875,70 39.25,70 L41.75,70 C42.44125,70 43,69.553 43,69 C43,68.447 42.44,68 41.75,68 Z" />
      <path d="M54,58 C54,57.447 53.44125,57 52.75,57 L50.25,57 C49.55875,57 49,57.447 49,58 C49,58.553 49.55875,59 50.25,59 L52.75,59 C53.44,59 54,58.553 54,58 Z" />
      <path d="M47,58 C47,57.447 46.44125,57 45.75,57 L43.25,57 C42.55875,57 42,57.447 42,58 C42,58.553 42.55875,59 43.25,59 L45.75,59 C46.44,59 47,58.553 47,58 Z" />
      <path d="M55.6948298,68 L53.261034,68 C52.5636822,68 52,68.447 52,69 C52,69.553 52.5636822,70 53.261034,70 L55.7465322,69.999 L55.7831021,68.999 L55.7831021,69.999 C56.480454,69.999 57,69.552 57,68.999 C57,68.447 56.3921816,68 55.6948298,68 Z" />
      <path d="M58.8707617,62 C59.1149092,62 59.3624475,61.9138788 59.5681644,61.7367153 C60.0598502,61.3159519 60.1434933,60.5420918 59.7580568,60.009371 C59.1895098,59.2232078 58.4559371,58.5736082 57.6342001,58.1294691 C57.0724349,57.8255845 56.3965082,58.0753358 56.1184514,58.6831051 C55.8403945,59.2921047 56.0687176,60.0302861 56.6270919,60.3329404 C57.1504265,60.6146797 57.6183757,61.0292915 57.9800757,61.5312548 C58.2038775,61.8388304 58.535059,62 58.8707617,62 Z" />
      <path d="M64.2465655,46.0999495 C63.7039958,46.3298082 63.1298077,46.4460145 62.5366487,46.4460145 L62.2647314,46.4460145 C61.5653349,46.4460145 61,47.0168303 61,47.7230073 C61,48.4291842 61.5653349,49 62.2647314,49 L62.5366487,49 C63.467491,49 64.3717739,48.816113 65.2242029,48.4547241 C65.8692159,48.1814477 66.1714867,47.4331299 65.9008342,46.7831406 C65.6314464,46.1331513 64.8852549,45.826673 64.2465655,46.0999495 Z" />
      <path d="M32.0719327,34.9370873 C32.7787639,34.7194095 33.1645535,34.0084964 32.9325402,33.3479134 C32.777415,32.9062665 32.6978291,32.4444875 32.6978291,31.9789338 C32.699178,31.7889376 32.7113182,31.5989413 32.7355987,31.4139781 C32.8286738,30.7244553 32.3052949,30.0940704 31.5660898,30.0085091 C30.8039531,29.9292392 30.1510784,30.4124084 30.0593522,31.0994147 C30.0215826,31.38378 30.0013489,31.6731783 30,31.9726425 C30,32.7124955 30.1241001,33.4397659 30.3682537,34.1343217 C30.5557528,34.6640464 31.0831784,35 31.6497225,35 C31.7900096,35 31.9329945,34.9811262 32.0719327,34.9370873 Z" />
      <path d="M27.75,68 L25.25,68 C24.55875,68 24,68.447 24,69 C24,69.553 24.55875,70 25.25,70 L27.75,70 C28.44125,70 29,69.553 29,69 C29,68.447 28.44,68 27.75,68 Z" />
      <path d="M38.881239,59 C39.3061201,59 39.7108753,58.7289099 39.8987175,58.2704303 C40.1547643,57.642051 39.9087805,56.9012379 39.3474901,56.6151567 C38.8230974,56.3465651 38.3479014,55.9430531 37.9744533,55.4458464 C37.5764068,54.9174081 36.8719986,54.8486986 36.3990388,55.2934363 C35.9260791,55.7369247 35.8645831,56.5252097 36.2626296,57.053648 C36.8485183,57.831939 37.5931784,58.4665646 38.4172241,58.8863171 C38.5681687,58.9625221 38.725822,59 38.881239,59 Z" />
      <path d="M65.3593141,36.8395283 C64.4425339,36.3599383 63.4410092,36.0784399 62.3842723,36.0031419 C61.6793531,35.9568048 61.0591782,36.4282857 61.0039659,37.067739 C60.9487537,37.7060338 61.4764801,38.2632386 62.1839674,38.3130511 C62.8542185,38.3605467 63.4898015,38.5389449 64.0714562,38.843612 C64.2743291,38.9501875 64.4964621,39 64.7147431,39 C65.1577252,39 65.5891512,38.7926411 65.8266923,38.4231019 C66.182362,37.8705309 65.973069,37.1615718 65.3593141,36.8395283 Z" />
      <path d="M34.75,68 L32.25,68 C31.55875,68 31,68.447 31,69 C31,69.553 31.55875,70 32.25,70 L34.75,70 C35.44125,70 36,69.553 36,69 C36,68.447 35.44,68 34.75,68 Z" />
      <path d="M37.1273679,52.1482492 C37.2335707,51.6097499 37.4567037,51.1121095 37.7914032,50.6679 C38.1421941,50.2027372 38.0402824,49.5468995 37.5629064,49.2032657 C37.0855305,48.8617273 36.4150586,48.9602077 36.063195,49.4264182 C35.5386179,50.1241624 35.1867542,50.9067674 35.0194045,51.7543275 C34.907838,52.3221614 35.288666,52.8711373 35.8711719,52.981142 C35.9387554,52.993714 36.0074118,53 36.0739226,53 C36.5781174,52.9979047 37.0276017,52.6490326 37.1273679,52.1482492 Z" />
      <path d="M55.5,18 C59.0832727,18 62,15.0832727 62,11.5 C62,7.91672727 59.0832727,5 55.5,5 C51.9167273,5 49,7.91672727 49,11.5 C49,15.0832727 51.9167273,18 55.5,18 Z M55.5,7.36363636 C57.7809091,7.36363636 59.6363636,9.21909091 59.6363636,11.5 C59.6363636,13.7809091 57.7809091,15.6363636 55.5,15.6363636 C53.2190909,15.6363636 51.3636364,13.7809091 51.3636364,11.5 C51.3636364,9.21909091 53.2202727,7.36363636 55.5,7.36363636 Z" />
      <path d="M14,36 C10.1395,36 7,39.1395 7,43 C7,46.8605 10.1395,50 14,50 C17.8605,50 21,46.8605 21,43 C21,39.1395 17.8605,36 14,36 Z M14,47.6666667 C11.4263333,47.6666667 9.33333333,45.5736667 9.33333333,43 C9.33333333,40.4263333 11.4263333,38.3333333 14,38.3333333 C16.5736667,38.3333333 18.6666667,40.4263333 18.6666667,43 C18.6666667,45.5736667 16.5736667,47.6666667 14,47.6666667 Z" />
    </svg>
  )
);
