import { css } from 'styled-components';

export const colorsVars = css`
  --color--black: rgba(0, 0, 0, 1);
  --color--white: rgba(255, 255, 255, 1);

  /* text */
  --color--text-primary: rgba(68, 68, 68, 1);
  --color--text-secondary: rgba(255, 255, 255, 1);
  --color--text-grey: rgba(102, 102, 102, 1);
  --color--text-lightGrey: rgba(204, 204, 204, 1);
  --color--text-black: rgba(0, 0, 0, 1);
  --color--text-primaryBlend: rgb(40, 93, 95);

  /* border */
  --color--border-dark: rgba(234, 234, 234, 1);
  --color--border-light: rgba(255, 255, 255, 0.78);

  /* background */
  --color--background-dark: rgba(27, 27, 27, 1);
  --color--background-middle: rgba(241, 241, 238, 1);
  --color--background-light: rgba(255, 255, 255, 1);
  --color--background-overlay: rgba(0, 0, 0, 0.25);
  --color--background-modal: rgba(0, 0, 0, 0.45);

  /* primary */
  --color--primary-main: rgba(75, 196, 247, 1);
  --color--primary-hover: rgba(70, 150, 200, 1);
  --color--primary-overlay: rgba(70, 150, 200, 0.8);

  /* secondary */
  --color--secondary-main: rgba(27, 27, 27, 1);
  --color--secondary-hover: rgba(15, 19, 25, 1);

  /* alert - danger */
  --color--alert-danger-main: #f44336;
  --color--alert-danger-hover: #9a2820;
`;
