import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const FacebookSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => (
    <svg
      id="facebook"
      aria-hidden="true"
      width="1em"
      height="1em"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="currentColor"
      className={className}
    >
      <path d="M512 85.3C512 40.4 471.6 0 426.7 0H85.3C40.4 0 0 40.4 0 85.3v341.3c0 45 40.4 85.4 85.3 85.4H256V318.6h-62.6v-85.3H256v-33C256 142.8 299.2 91 352.1 91H421v85.3h-68.8c-7.4 0-16.5 9.1-16.5 22.8v34.1H421v85.3h-85.3V512h91c44.9 0 85.3-40.4 85.3-85.3V85.3z" />
    </svg>
  )
);
