import { createContext, Locale } from '~src/utils';

type LocaleContext = {
  locale: Locale;
  pageID: string;
  uid?: string;
};

const [LocaleProvider, useLocaleContext] = createContext<LocaleContext>({
  strict: true,
  name: 'LocaleContext',
  errorMessage:
    'useLocaleContext must be inside a LocaleProvider with a value`',
});

export { LocaleProvider, useLocaleContext };
