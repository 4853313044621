import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const InstagramSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => (
    <svg
      id="instagram"
      aria-hidden="true"
      width="1em"
      height="1em"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="currentColor"
      className={className}
    >
      <path d="M256.1 124.7c-72.6 0-131.2 58.6-131.2 131.3s58.6 131.3 131.2 131.3S387.3 328.7 387.3 256s-58.6-131.3-131.2-131.3zm0 216.6c-46.9 0-85.3-38.3-85.3-85.3s38.3-85.3 85.3-85.3 85.3 38.3 85.3 85.3-38.4 85.3-85.3 85.3zm167.2-221.9c0 17-13.7 30.6-30.6 30.6-17 0-30.6-13.7-30.6-30.6s13.7-30.6 30.6-30.6 30.6 13.7 30.6 30.6zm86.9 31c-1.9-41-11.3-77.3-41.3-107.3-30-29.9-66.3-39.2-107.3-41.3-42.3-2.4-168.9-2.4-211.2 0C109.5 3.7 73.2 13.1 43.1 43S3.9 109.3 1.8 150.3c-2.4 42.3-2.4 169 0 211.2 1.9 41 11.3 77.3 41.3 107.3 30 29.9 66.2 39.3 107.3 41.4 42.3 2.4 168.9 2.4 211.2 0 41-1.9 77.3-11.3 107.3-41.4 29.9-29.9 39.3-66.3 41.3-107.3 2.4-42.2 2.4-168.8 0-211.1zm-54.6 256.5c-8.9 22.4-26.2 39.6-48.7 48.7-33.7 13.4-113.7 10.3-150.9 10.3s-117.3 3-150.9-10.3c-22.4-8.9-39.6-26.2-48.7-48.7C43.1 373.2 46.2 293.2 46.2 256s-3-117.3 10.3-150.9c8.9-22.4 26.2-39.6 48.7-48.7C138.9 43 218.8 46.1 256.1 46.1s117.3-3 150.9 10.3c22.4 8.9 39.6 26.2 48.7 48.7C469.1 138.8 466 218.8 466 256s3 117.3-10.4 150.9z" />
    </svg>
  )
);
