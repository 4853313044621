import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { maxWidth } from '~src/styles';
import { ButtonEvent } from '~src/utils';

const Hamburger = styled.button`
  position: relative;
  width: var(--header--height);
  height: var(--header--height);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index--hamburger);
  ${maxWidth.tablet`
    width: var(--header--phone-height);
    height: var(--header--phone-height);
  `}
`;

const Bar = styled.span<{ isOpen: boolean }>`
  position: relative;
  width: 30px;
  height: 2px;
  background: var(--color--white);
  background: ${p => p.isOpen && 'transparent'} !important;
  transition: background 0.2s ease;
  ${maxWidth.phone`
    width: 26px;
  `}

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${p => (p.isOpen ? `var(--color--white)` : 'inherit')};
    left: 0;
    top: ${p => (p.isOpen ? '0' : '-10px')};
    transform: ${p => (p.isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    transition-delay: ${p => (p.isOpen ? '0s, 0.2s' : '0.2s, 0s')};
    transition-duration: 0.2s;
    transition-property: top, transform;
    ${p => maxWidth.phone`
      top: ${p.isOpen ? '0' : '-8px'};
    `}
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${p => (p.isOpen ? `var(--color--white)` : 'inherit')};
    left: 0;
    bottom: ${p => (p.isOpen ? '0' : '-10px')};
    transform: ${p => (p.isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    transition-delay: ${p => (p.isOpen ? '0s, 0.2s' : '0.2s, 0s')};
    transition-duration: 0.2s;
    transition-property: bottom, transform;
    ${p => maxWidth.phone`
      bottom: ${p.isOpen ? '0' : '-8px'};
    `}
  }
`;

type Props = {
  isOpen: boolean;
  toggleMenu: ButtonEvent;
};

export const MenuBurger: FC<Props> = memo(({ isOpen, toggleMenu }) => (
  <Hamburger
    aria-label="Toggle menu"
    aria-expanded={isOpen}
    onClick={toggleMenu}
  >
    <Bar className="burgerBar" isOpen={isOpen} />
  </Hamburger>
));
