import { css } from 'styled-components';

const defaultFonts = `
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol'
`;

export const fontVars = css`
  --font--body: 'Comfortaa', ${defaultFonts};
  --font--heading: 'Comfortaa', ${defaultFonts};
  --font--cursive: 'Caveat', 'cursive', ${defaultFonts};
`;
