import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
  path {
    fill: var(--color--background-dark);
  }
`;

const MenuTriangle = () => (
  <Wrapper
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M361 512.1h151v-151l-151 151zm0-181.8h151V181.9L361 330.3zM512 .1l-151 151h151V.1zm-332.8 512h151v-151l-151 151zm0-181.8h151V181.9m0-181.8l-151 151h151V.1zM0 512.1h148.5v-151L0 512.1zm0-181.8h148.5V181.9L0 330.3zM148.5.1L0 151.1h148.5V.1z" />
  </Wrapper>
);

export default MenuTriangle;
