import 'focus-visible/dist/focus-visible';

export { wrapPageElement } from './src/gatsby/wrap-page-element';
export { wrapRootElement } from './src/gatsby/wrap-root-element';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);

  return false;
};
