import { Link } from 'gatsby';
import React, { FC, memo } from 'react';
import styled from 'styled-components';

import locales from '~config/i18n';
import { useLocaleContext } from '~src/context/locale-context';

const LocLink = styled(Link)<{ $isActive: boolean }>`
  border-bottom: 1px solid;
  border-bottom-color: ${p => (p.$isActive ? `initial` : `transparent`)};
  text-transform: uppercase;
`;

export const LocaleSwitch: FC = memo(() => {
  const { pageID, locale, uid } = useLocaleContext();

  return (
    <>
      {locales.map((lang, i) => {
        const slugData = uid
          ? lang.slugs.find(el => el.pageID === `rooms`)
          : lang.slugs.find(el => el.pageID === pageID);

        const slug = slugData ? slugData.localSlug : ``;

        const roomSlugData = uid
          ? lang.roomSlugs.find(el => el.pageID === pageID)
          : ``;

        const roomSlug = roomSlugData ? roomSlugData.localSlug : ``;

        const path =
          pageID && slug
            ? uid
              ? `${lang.startUrl}${slug}/${roomSlug}/`
              : `${lang.startUrl}${slug}/`
            : `${lang.startUrl}`;

        const lastIndex = locales.length - 1 === i;

        return (
          <span key={lang.id}>
            <LocLink
              title={lang.languageName}
              $isActive={locale === lang.locale}
              hrefLang={lang.htmlLang}
              to={path}
            >
              {lang.locale}
            </LocLink>
            {!lastIndex ? <span> | </span> : null}
          </span>
        );
      })}
    </>
  );
});
