import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const PhoneSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => (
    <svg
      aria-hidden="true"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="#fff"
      fill="none"
      className={className}
    >
      <path d="M10.2 7.5a1 1 0 0 0 0-1.4L7.8 3.7a2.4 2.4 0 0 0-3.4 0l-.5.5a3.6 3.6 0 0 0-.4 4.3 25.3 25.3 0 0 0 12 12 3.7 3.7 0 0 0 4.3-.4l.5-.5a2.4 2.4 0 0 0 0-3.4l-2.4-2.4a1 1 0 0 0-1.4 0l-1.4 1.4a8.5 8.5 0 0 1-6.2-6.4z" />
    </svg>
  )
);
