import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { graphql, useStaticQuery } from 'gatsby';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { ExternalLink } from '~src/components/external-link';
import { LocaleSwitch } from '~src/components/locale-switch';
import { Link } from '~src/components/localized-link';
import { LogoSVG } from '~src/components/logo-svg';
import { useLocaleContext } from '~src/context/locale-context';
import { PhoneSVG } from '~src/icons';
import { maxWidth, Media } from '~src/styles';
import {
  StrapiNavMenuConnection,
  StrapiNavMenuLinks,
} from '~src/types/generated/graphql-gatsby';

import { Menu, MenuBurger, MenuData } from './menu';

const Nav = styled.nav`
  position: fixed;
  top: 1em;
  right: 1em;
  bottom: 0;
  left: 1em;
  height: 4.6em;
  z-index: var(--z-index--nav);
  box-shadow: rgba(0, 0, 0, 0.04) 0 1px 1px 0, rgba(0, 0, 0, 0.04) 0 2px 2px 0,
    rgba(0, 0, 0, 0.04) 0 4px 4px 0, rgba(0, 0, 0, 0.04) 0 8px 8px 0,
    rgba(0, 0, 0, 0.04) 0 16px 16px 0;

  @supports (backdrop-filter: blur(15px)) or
    (-webkit-backdrop-filter: blur(15px)) {
    backdrop-filter: blur(15px);
    background-color: rgba(70, 100, 100, 0.5);
  }

  background-color: rgba(70, 100, 100, 0.9);
`;

const NavInner = styled.div`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0 2em;
  margin: 0 auto;

  ${maxWidth.desktop`
    padding-right: 0;
  `}
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 3.4em;
  line-height: 1;
  border: none;

  > svg {
    fill: var(--color--primary-main);
  }
`;

const Right = styled.ul`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  margin-right: 1.2em;
  font-size: 0.9em;

  &.locale-switch-item {
    margin-right: 0;
    margin-left: 1.2em;

    ${maxWidth.desktop`
      margin-right: 1.2em;
    `}

    ${maxWidth.phone`
      display: none;
    `}
  }

  &:last-of-type {
    margin-right: 0;
  }

  a,
  span {
    color: white;
    font-size: 1em;
    letter-spacing: 0.05em;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    @media (hover: none) {
      opacity: 1 !important;
    }
  }
`;

const MenuLink = styled(Link)<{ $isActive: boolean }>`
  border-bottom: 1px solid;
  border-bottom-color: ${p => (p.$isActive ? `initial` : `transparent`)};
`;

const Phone = styled(ExternalLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  transition: all 0.2s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  > span {
    letter-spacing: 0.1em;
    ${maxWidth.phone`
      display: none;
    `}
  }

  > svg {
    margin: 0 0.5em;
    height: 1.5em;
    width: 1.5em;
    transition: fill 0.3s;
    ${maxWidth.phone`
      height: 2.5em;
      width: 2.5em;
    `}

    @media (prefers-reduced-motion) {
      transition: none;
    }
  }

  &:hover {
    opacity: 0.7;
  }
  @media (hover: none) {
    opacity: 1 !important;
  }
`;

const navMenuQuery = graphql`
  query NavMenuQuery {
    allStrapiNavMenu {
      nodes {
        locale
        links {
          id
          link {
            pageID
            name
          }
        }
      }
    }
    phoneInfo: strapiInfo {
      phone
    }
  }
`;

const options = {
  reserveScrollBarGap: true,
  // allowTouchMove: element => element.id === 'no-scroll-lock',
};

type NavMenuQuery = {
  allStrapiNavMenu: StrapiNavMenuConnection;
  phoneInfo: {
    phone: string;
  };
};

export const Header: FC = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const { locale, pageID } = useLocaleContext();

  const { allStrapiNavMenu, phoneInfo } =
    useStaticQuery<NavMenuQuery>(navMenuQuery);

  const menuLinks = useMemo(
    () => allStrapiNavMenu.nodes.find(node => node.locale === locale)?.links,
    [allStrapiNavMenu.nodes, locale]
  ) as StrapiNavMenuLinks[];

  const wrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      // disable when open
      if (openMenu) {
        disableBodyScroll(wrapperRef.current, options);
      } else {
        // enable when closed
        clearAllBodyScrollLocks();
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
    // listen for modalShown state
  }, [openMenu]);

  const closeMenu = useCallback(() => setOpenMenu(false), []);
  const toggleMenu = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

  return (
    <>
      <Nav ref={wrapperRef}>
        <NavInner>
          <Logo id="logo" aria-label="Home" to="/">
            <LogoSVG />
          </Logo>
          <Media greaterThanOrEqual="desktop">
            {(mediaClassNames, renderChildren) => (
              <Right className={mediaClassNames}>
                {renderChildren && (
                  <>
                    {menuLinks.map(el => {
                      const isActive =
                        el.link?.pageID === pageID ||
                        (el.link?.pageID === '/' && pageID === '');

                      return (
                        <Item key={el.id} className="menu-link-item">
                          <MenuLink
                            to={el.link?.pageID || '/'}
                            $isActive={isActive}
                          >
                            {el.link?.name}
                          </MenuLink>
                        </Item>
                      );
                    })}
                    <Item>
                      <Phone
                        to={`tel: ${phoneInfo.phone}`}
                        className="phone-link"
                      >
                        <PhoneSVG />
                        <span>{phoneInfo.phone}</span>
                      </Phone>
                    </Item>
                    <Item className="locale-switch-item">
                      <LocaleSwitch />
                    </Item>
                  </>
                )}
              </Right>
            )}
          </Media>
          <Media lessThan="desktop">
            {(mediaClassNames, renderChildren) => (
              <Right className={mediaClassNames}>
                {renderChildren && (
                  <>
                    <Item>
                      <Phone
                        to={`tel: ${phoneInfo.phone}`}
                        className="phone-link"
                      >
                        <PhoneSVG />
                        <span>{phoneInfo.phone}</span>
                      </Phone>
                    </Item>
                    <Item className="locale-switch-item">
                      <LocaleSwitch />
                    </Item>
                    <Item className="hamburger">
                      <MenuBurger isOpen={openMenu} toggleMenu={toggleMenu} />
                    </Item>
                  </>
                )}
              </Right>
            )}
          </Media>
        </NavInner>
      </Nav>
      <Media lessThan="desktop">
        <Menu
          isOpen={openMenu}
          closeMenu={closeMenu}
          data={menuLinks as unknown as MenuData}
        />
      </Media>
    </>
  );
};
