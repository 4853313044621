import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { __DEV__, createContext } from '~src/utils';

type DrawerContext = {
  bookOpen: boolean;
  setBookOpen: Dispatch<SetStateAction<boolean>>;
};

const [Provider, useDrawerContext] = createContext<DrawerContext>({
  strict: true,
  name: 'DrawerContext',
  errorMessage: 'useDrawerContext must be inside a MenuProvider with a value`',
});

const DrawerProvider: FC = ({ children }) => {
  const [bookOpen, setBookOpen] = useState(false);

  const context = {
    bookOpen,
    setBookOpen,
  };

  return <Provider value={context}>{children}</Provider>;
};

if (__DEV__) {
  DrawerProvider.displayName = 'DrawerProvider';
}

export { DrawerProvider, useDrawerContext };
