import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    small: 0,
    phone: 576,
    tablet: 769,
    desktop: 992,
    xLarge: 1800,
  },
  interactions: {
    hover: '(hover: hover)',
    notHover: '(hover: none)',
    landscape: 'not all and (orientation: landscape)',
    portrait: 'not all and (orientation: portrait)',
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
