import { css } from 'styled-components';

export const zIndexVars = css`
  --z-index--hide: -1;
  --z-index--auto: auto;
  --z-index--base: -1;
  --z-index--tab: 2;
  --z-index--menu: 3;
  --z-index--docked: 10;
  --z-index--nav: 11;
  --z-index--hamburger: 12;
  --z-index--dropdown: 1000;
  --z-index--sticky: 1100;
  --z-index--banner: 1200;
  --z-index--overlay: 1300;
  --z-index--modal: 1400;
  --z-index--popover: 1500;
  --z-index--toast: 1700;
  --z-index--tooltip: 1800;
  --z-index--a11yIndicators: 10000;
`;
