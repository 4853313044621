import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const LogoSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => (
    <svg
      aria-label="Hotel Maestral logo"
      width="1em"
      height="1em"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="#4BC4F7"
      className={className}
    >
      <path d="M74.9 272v238.5s78.6-19.1 92.9-96.2c.5-2.5.7-4.9.7-7.4 0-87.1.1-237.2.1-237.2S88 194.1 75.4 265.6c-.3 2.1-.5 4.2-.5 6.4zM343.3 270.4v241.4s78.7-19.2 93-96.4c.4-2.3.6-4.6.6-6.9 0-87.1.1-237.4.1-237.4s-83.1 25.2-93.7 99.3z" />
      <path d="M343.3 102v238.8s81.2-19.8 93.6-100.1c0-86.5.1-240.6.1-240.6s-80.8 24.4-93.2 96.1c-.3 1.9-.5 3.8-.5 5.8zM213.6 283.8V512s61.5-10.8 86.9-83.6c1.7-4.9 2.6-10 2.6-15.1v-133l-45.8 42.6-43.7-39.1zM213.6 223.4l44.4 36.4 43-39.1.2-122.6s-63 20.6-83.9 73.4c-3.2 8.1-4.7 16.8-4.6 25.6.3 15.9.9 26.3.9 26.3z" />
    </svg>
  )
);
