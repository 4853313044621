import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby';
import React from 'react';

// import { PortalManager } from '~src/components/elements/portal';
import { DrawerProvider } from '~src/context/drawer-context';
import { MediaContextProvider } from '~src/styles';

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}: WrapRootElementBrowserArgs) => (
  <QueryClientProvider client={queryClient}>
    <MediaContextProvider>
      {/* <PortalManager> */}
      <DrawerProvider>
        {element}
        <ReactQueryDevtools initialIsOpen={false} />
      </DrawerProvider>
      {/* </PortalManager> */}
    </MediaContextProvider>
  </QueryClientProvider>
);
