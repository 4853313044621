import { Link as GatsbyLink, navigate } from 'gatsby';
import React, { FC, useMemo } from 'react';

import locales from '~config/i18n';
import { useLocaleContext } from '~src/context/locale-context';
import { AnchorEvent, Locale } from '~src/utils';

export const navigateLocalized = (to: string, locale: Locale): void => {
  const localeData = locales.find(el => el.locale === locale);

  const isIndex = to === '/';

  const localizedSlug = localeData?.slugs.find(
    el => el.pageID === to
  )?.localSlug;

  // in gatsby Link anchor links '#' are not recomended
  const localizedPath = `${localeData?.startUrl}${
    isIndex ? '' : localizedSlug ? `${localizedSlug}/` : ''
  }`;

  navigate(localizedPath);
};

type Props = {
  to: string;
  className?: string;
  id?: string;
  onClick?: AnchorEvent;
  children: string;
};

export const Link: FC<Props> = ({ to, className, id, onClick, ...props }) => {
  const { locale } = useLocaleContext();

  // in gatsby Link anchor links '#' are not recomended

  const localizedPath = useMemo(() => {
    const localeData = locales.find(el => el.locale === locale);
    const slugData = localeData?.slugs.find(el => el.pageID === to);
    const roomSlugData = localeData?.roomSlugs.find(el => el.pageID === to);
    const roomsSlug = localeData?.slugs.find(
      el => el.pageID === `rooms`
    )?.localSlug;
    const localizedSlug = slugData
      ? `${slugData.localSlug}/`
      : roomSlugData
      ? `${roomsSlug}/${roomSlugData.localSlug}/`
      : ``;

    return `${localeData?.startUrl}${localizedSlug}`;
  }, [locale, to]);

  return (
    <GatsbyLink
      to={localizedPath}
      className={className}
      id={id}
      onClick={onClick}
      {...props}
    />
  );
};
