import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { Link } from '~src/components/localized-link';
import { maxWidth, minWidth } from '~src/styles';
import { AnchorEvent } from '~src/utils';

export * from './menu-burger';

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color--gray15);
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  visibility: ${p => (p.isOpen ? 'visible' : 'hidden')};
  transform: ${p => (p.isOpen ? 'translateY(0)' : 'translateY(100%)')};
  transition: 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  z-index: var(--z-index--menu);

  @supports (backdrop-filter: blur(16px)) or
    (-webkit-backdrop-filter: blur(16px)) {
    backdrop-filter: blur(16px);
    background-color: rgba(36, 42, 58, 0.5);
  }

  background-color: rgba(70, 100, 100, 0.9);

  ${maxWidth.phone`
    padding: 0;  
  `}
`;

const MenuNav = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 30em;
  width: 100%;
  margin: 0 auto;
  transform: ${p => (p.isOpen ? 'scale(1)' : 'scale(.8)')};
  opacity: ${p => (p.isOpen ? '1' : '0')};
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  ${maxWidth.phone`
    padding: 10%;
    max-width: 100%;
  `}
`;

const MenuLink = styled(Link)`
  font-size: 2em;
  padding: 1em 0;
  font-weight: normal;
  color: var(--color--white);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  &:last-of-type {
    border-bottom: none;
  }

  ${maxWidth.tablet`
    font-size: 1.4em;
  `}

  transition: all 0.5s ease-in-out;
  &:hover {
    color: var(--color--primary-main);

    ${minWidth.tablet`
      transition: all 0.5s ease-in-out;
      padding: 1em;
    `}
  }
`;

export type MenuData = {
  id: number;
  link: {
    name: string;
    pageID: string;
  };
}[];

type Props = {
  isOpen: boolean;
  closeMenu: AnchorEvent;
  data: MenuData;
};

export const Menu: FC<Props> = memo(({ isOpen, closeMenu, data }) => {
  return (
    <Wrapper aria-hidden={!isOpen} isOpen={isOpen}>
      <MenuNav isOpen={isOpen}>
        {data.map(({ link, id }) => (
          <MenuLink key={id} to={link.pageID} onClick={closeMenu}>
            {link.name}
          </MenuLink>
        ))}
      </MenuNav>
    </Wrapper>
  );
});
