import { css } from 'styled-components';

import { colorsVars } from './colors';
import { fontVars } from './fonts';
import { zIndexVars } from './zIndexes';

export * from './responsive';

export const vars = css`
  ${colorsVars}
  ${fontVars}
  ${zIndexVars}

  --container--width: 80em;
  --header--height: 5em;
  --header--phone-height: 5em;
`;
