import { __DEV__, isFunction } from './assertion';
import { AnyFunction, FunctionArguments } from './types';

export function runIfFn<T, U>(
  valueOrFn: T | ((...args: U[]) => T),
  ...args: U[]
): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

export function callAllHandlers<T extends (event: any) => void>(
  ...fns: (T | undefined)[]
) {
  return function func(event: FunctionArguments<T>[0]): void {
    fns.some(fn => {
      fn?.(event);
      return event?.defaultPrevented;
    });
  };
}

export function callAll<T extends AnyFunction>(...fns: (T | undefined)[]) {
  return function mergedFn(arg: FunctionArguments<T>[0]): void {
    fns.forEach(fn => {
      fn?.(arg);
    });
  };
}

export function once(fn?: ((...args: any[]) => any) | null): any {
  let result: any;

  return function func(this: any, ...args: any[]) {
    if (fn) {
      result = fn.apply(this, args);
      fn = null;
    }

    return result;
  };
}

export function noop(): void {
  return;
}

type MessageOptions = {
  condition: boolean;
  message: string;
};

export const warn = once((options: MessageOptions) => {
  const { condition, message } = options;
  if (condition && __DEV__) {
    console.warn(message);
  }
});

export const error = once((options: MessageOptions) => {
  const { condition, message } = options;
  if (condition && __DEV__) {
    console.error(message);
  }
});
