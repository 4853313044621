import React, { FC, memo, ReactNode } from 'react';

type Props = {
  to: string;
  title?: string;
  className?: string;
  children: ReactNode;
};

export const ExternalLink: FC<Props> = memo(
  ({ children, to, title = '', className }) => (
    <a
      href={to}
      title={title}
      rel="noreferrer noopener"
      target={
        to.startsWith('tel:') || to.startsWith('mailto:') ? undefined : '_blank'
      }
      className={className}
    >
      {children}
    </a>
  )
);
