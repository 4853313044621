import { css } from 'styled-components';

const SCROLLBAR_WIDTH = 6;

export const customScrollbar = css`
  /* Chrome */
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    height: ${SCROLLBAR_WIDTH}px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color--primary-main);
  }
  &::-webkit-scrollbar-track {
    background: var(--color--background-dark);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color--primary-hover);
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color--primary-main) var(--color--background-dark);
`;
