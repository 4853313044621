import type {
  GatsbyBrowser,
  PageProps,
  WrapPageElementBrowserArgs,
} from 'gatsby';
import React from 'react';

import AppLayout from '~src/layouts/app-layout';
import { PageContext } from '~src/utils';

type WrapPageElement = WrapPageElementBrowserArgs & {
  props: PageProps & {
    pageContext: PageContext;
  };
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElement) => <AppLayout {...props}>{element}</AppLayout>;
